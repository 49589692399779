.navbar {
  border-bottom: solid 1px #EBEBEB;
}

.bottom-tab-nav {
  border-top: solid 1px #EBEBEB;
}

.nav-link,
.bottom-nav-link {
  color: #55575b;
}

.bottom-nav-link.active {
  color: #001b6c;
}

.bottom-tab-label {
  font-size: 12px;
}

.rce-mbox-text {
  white-space: pre-line;
  text-align: left;
  line-height: 26px;
}
.rce-mbox-photo--img {
  max-width: 300px;
  text-align: left;
}
.rce-mbox-photo--img img {
  width: 100%;
}

.filebox label {
  display: inline-block;
  padding: .5em .75em;
  color: #999;
  font-size: inherit;
  line-height: normal;
  vertical-align: middle;
  background-color: #fdfdfd;
  cursor: pointer;
  border: 1px solid #ebebeb;
  border-bottom-color: #e2e2e2;
  border-radius: .25em;
}

.filebox input[type="file"] { /* 파일 필드 숨기기 */
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.ball {
  width: 50px;
  height: 50px;
  background-image: url("/authentication_image.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /*border-radius: 50%;*/
  /*background-color: #FF5722;*/
  animation: bounce 0.5s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
  animation-iteration-count: infinite;
}

@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, 50px, 0);
  }
}
/* Prefix Support */

ball {
  -webkit-animation-name: bounce;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: cubic-bezier(
          .5, 0.05, 1, .5);
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 200px, 0);
    transform: translate3d(0, 200px, 0);
  }
}
